<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="value"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formattedValue"
        :label="$t(label)"
        :prepend-icon="icon"
        readonly
        :color="color"
        v-on="!readonly && on"
      >
        <v-tooltip slot="append" bottom v-if="$t(description)">
          <template v-slot:activator="{ on }">
            <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
          </template>
          <span>{{ $t(description) }}</span>
        </v-tooltip>
      </v-text-field>
    </template>
    <v-date-picker
      v-if="menu"
      ref="picker"
      v-model="value"
      v-on:change="$refs.menu.save(value)"
      :color="color"
      :first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import format from 'date-fns/format';
import en from 'date-fns/locale/en-US';
import fr from 'date-fns/locale/fr';
import baseTextInput from '@/mixins/baseTextInput';

const dateLocales = {
  en,
  fr,
};

export default {
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.date',
    },
    description: {
      type: String,
      default: 'fields.dateTooltip',
    },
  },
  data: () => ({
    value: null,
    menu: false,
  }),
  computed: {
    /**
     * Format the value to display in locale format
     *
     * @return {String} date in locale format or empty if value is not valid
     */
    formattedValue() {
      const date = new Date(this.value);
      // Check if date is valid to prevent format error
      if (!Number.isNaN(date.getTime())) {
        return format(date, 'P', { locale: dateLocales[this.$i18n.locale] });
      }
      return '';
    },
  },
  created() {
    // Default value is handle in baseTextInput mixin
    const value = new Date(this.value);

    // Check if date is valid to be formatted otherwise set empty value
    this.value = !Number.isNaN(value.getTime())
      ? value.toISOString().substring(0, 10) // Format the value in yyyy-MM-dd
      : '';
  },
};
</script>
